
import Kirbyimage from "../components/kirbyimage.vue";
export default {
  components: { Kirbyimage },
  scrollToTop: true,
  layout: "default",
  transition: "fade",
  data() {
    return {
      head: {},
      page: {},
      subpages: {},
      openAccordian: null,
    };
  },
  head() {
    return this.head;
  },
  async fetch() {
    let self = this;

    const path =
      this.$route.path == "/" ? "home" : this.$route.path.substring(1);

    let page = this.$store.state.directory[path];

    console.log("directory from state", this.$store.state.directory.length);

    await self.$axios
      .$post(`/api/content`, {
        query: "page('" + path + "')",
        select: {
          parent_title: "page.parent.title",
          title: "page.title",
          head: "page.title.toSeoHelper",
          blocks: "page.blocks.toBlocksHelper",
          template: "page.intendedTemplate",
          listing: "page.blocks.toListingHelper",
          siblings: {
            query: "page.siblings",
            select: {
              page: "page.title",
              template: "page.intendedTemplate",
              listing: "page.blocks.toListingHelper",
            },
          },
          subpages: {
            query: "page.children",
            select: {
              page: "page.title",
              template: "page.intendedTemplate",
              listing: "page.blocks.toListingHelper",
            },
          },
        },
      })
      .then((resp) => {
        console.log("client response", resp.result);
        page = resp.result;

        if (page != null) {
          this.$store.commit("page/setPageContent", page);
          this.head = page.head;
          this.blocks = page.blocks;
          this.page = page;
          this.subpages = page.subpages;

          console.log("client page found", this.page);
        } else {
          console.log("page is null", page == null);
          this.$nuxt.error({ statusCode: 404, message: "Page not found" });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        if (path != "search") {
          return self.$nuxt.error({
            statusCode: 404,
            message: error.message,
          });
        }
      });
  },
};
